html {
  margin: 0;
  font-family: "poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
}

#root{
  height: 100%;
}

body {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

header {
  max-height: 40%;
  background-color: #f1f1f1;
}

footer {
  background-color: #f1f1f1;
  margin-bottom: 0px;
}

main {
  flex-grow: 1;
  background-color: #ffffff;
}

