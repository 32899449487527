/* App.css */
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  height: 60vmin;
  max-width: 50vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  max-height: 40%;
}

.App-footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #D1D3D4;
  text-align: center;
}

.main-content{
  padding-top: 20px;
}